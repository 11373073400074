body {
  margin: 0;
}

.icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  justify-items: center;
  background-color: rgb(230, 230, 230);
}

.icons, a {
  color: rgb(55, 55, 55);
}
@media (orientation: landscape) {
  .App {
    margin-left: auto;
    margin-right: auto;
    margin-top:5vh;
    max-width: 1024px;
  }
  
  .icons {
    font-size: 20vh;
  }
}

@media (orientation: portrait) {
  .App {
    margin: 5vh;
  }

  .icons {
    font-size:18vh;
  }
}

button {
  background:none;
  color:inherit;
  border:none; 
  padding:0!important;
  font: inherit;
  cursor: pointer;
}
button:focus {outline:0;}
